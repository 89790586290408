import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Notfound from './component/Notfound';
import Navbar from './component/Bars/Navbar';
import SideBar from './component/Bars/SideBar';
import './AppStyle.css';
import Occupation from './component/occupation/occupation';
import ReferralDoctor from './component/referralDoctor/ReferralDoctor';
import AddTest from './component/labbirtory/AddTest';
import AddMainTest from './component/labbirtory/AddMainTest';
import AddDrugs from './component/Drugs/AddDrugs';
import SearchScreen from './component/search/SearchScreen';
import NewInvoice from './component/newinvoice/NewInvoces';
import TodayPatientScreen from './component/todayPatient/TodayPatientScreen';
import TabsVisit from './component/todayPatient/EachVisition/TabsVisit';
import LoginForm from './component/account/LoginForm';
import {useSelector} from 'react-redux';
import AddUserForm from "./component/account/addUser";
import User from "./component/account/Users";
import EditUser from "./component/account/editUser";
import Slideshow from "./component/showImageSilde/ImageSilde";
import ReportScreen from "./component/report/ReportScreen";
import ReportTable from "./component/report/ReportTable";

function App() {
    const login = true;
    const userlogin = useSelector(state => state.userLogin);
    
    return (
        <>
            {(userlogin.userInfo != undefined && userlogin.userInfo != null) ? (
                <div>
                    <Navbar/>
                    <SideBar/>
                    <div className='App' style={{backgroundColor: '#f4f6f9'}}>
                        <div className='content-wrapper topSpace'>
                            <section className='tableSection'>
                                <Switch>
                                    <Route exact path='/' component={NewInvoice}/>
                                    <Route exact path='/occupation' component={Occupation}/>
                                    <Route
                                        exact
                                        path='/referralDoctor'
                                        component={ReferralDoctor}
                                    />
                                    <Route exact path='/addTest' component={AddTest}/>
                                    <Route exact path='/addMainTest' component={AddMainTest}/>
                                    <Route exact path='/addDrugs' component={AddDrugs}/>
                                    <Route exact path='/users' component={User}/>
                                    <Route exact path="/editUser" component={EditUser}/>

                                    <Route exact path='/search' component={SearchScreen}/>
                                    <Route
                                        exact
                                        path='/todayPatient'
                                        component={TodayPatientScreen}
                                    />
                                    <Route exact path='/newInvoice' component={NewInvoice}/>
                                    <Route exact path='/tabsVisit/:id' component={TabsVisit}/>
                                    <Route exact path='/Slideshow' component={Slideshow}/>

                                    <Route exact path='/loginForm' component={LoginForm}/>
                                    <Route exact path='/register' component={AddUserForm}/>
                                    <Route exact path='/reportAll' component={ReportScreen}/>
                                    <Route exact path='/reportTable' component={ReportTable}/>
                                    <Route>
                                        <Notfound/>
                                    </Route>
                                </Switch>
                            </section>
                        </div>
                    </div>
                </div>
            ) : (
                <LoginForm/>
            )}
        </>
    );
}

export default App;
