import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import ReportTable from "./ReportTable";
import {getReport} from "./reportReducer";

function ReportScreen() {
    const [oneDate, setOneDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    return (
        <div className="card rounded-top mr-2">
            <div className="card-header bg-info">Report</div>
            <div className="card-body">
                <div className="jumbotron">
                    <div className="form-group">
                        <label>By Date</label>
                        <br/>
                        <DatePicker
                            placeholderText="select a date"
                            className="form-control"
                            selected={oneDate}
                            onChange={(date) => setOneDate(date)}

                        />
                    </div>
                    <button className="btn btn-primary" onClick={e => {
                        if(oneDate==null||oneDate==undefined){
                            return
                        }
                        getReport(oneDate.toISOString().split("T")[0], oneDate.toISOString().split("T")[0])
                    }}>Report
                    </button>
                    <hr/>
                    <div>
                        <label>Between Two Date</label>

                        <div className="form-group">
                            <label>From</label>


                            <DatePicker
                                placeholderText="select a date"
                                className="form-control"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}

                            />
                            <label className="ml-3">To</label>
                            <DatePicker
                                placeholderText="select a date"
                                className="form-control"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}

                            />
                        </div>

                        <button className="btn btn-primary" onClick={e => {
                            if(fromDate==null||fromDate==undefined||toDate==null||toDate==undefined){
                                return
                            }
                            getReport(fromDate.toISOString().split("T")[0], toDate.toISOString().split("T")[0])
                        }}>Report
                        </button>
                        <br/>
                        <br/>
                        <ReportTable />
                    </div>
                </div>
            </div>

        </div>
    )
}


export default ReportScreen;