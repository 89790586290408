import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {createSystemReviews, upDateSystemReviews} from "./system_review";

const SystemRivew = (props) => {
    
    let isComplete = props.state.state == "completed";
    const [state, setState] = useState({});
    let ex = props.state?.systemReview;
    
    useEffect(() => {
        let temp = {};
        if (ex.length > 0) {
            ex?.forEach((e) => {
                temp[e.type] = e.note;
            });
            setState({...temp});
        } else {
            setState({
                GENERAL: "0",
                CVS: "0",
                RES: "0",
                GIT: "0",
                GUS: "0",
                NEU: "0",
                PSY: "0",
                DER: "0",
                ENDO: "0",
                note: "",
            });
        }
    }, []);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        

        
        if (props.state?.systemReview.length === 0) {
            
        } else {
            
        }
        
        let data = [];
        Object.entries(state).forEach(([key, value]) => {
            
            data.push({type: key, note: value});
        });
        let note = [];
        let types = [];
        data.forEach((e) => {
            types.push(e.type);
            note.push(e.note);
        });
        

        

        if (props.state?.systemReview?.length > 0) {
            
            let ids = [];

            props.state?.systemReview.forEach((er) => {
                ids.push(er.id);
            });
            upDateSystemReviews(note, types, ids, props.state.id);
        } else {
            

            createSystemReviews(note, types, props.state.id);
        }
    };

    return (
        <></>
    );
};

export default SystemRivew;
