import swal from "sweetalert";
import api from "../constants/api";
import {
  ADD_LAB_REQUEST,
  ADD_LAB_SUCCESS,
  ADD_LAB_FAIL,
  GET_LAB_REQUEST,
  GET_LAB_SUCCESS,
  GET_LAB_FAIL,
  DELETE_LAB_REQUEST,
  DELETE_LAB_SUCCESS,
  DELETE_LAB_FAIL,
} from "../constants/labConstants";
import { getToken } from "../reducers/userReducers";

export const addLabAction =
  (description, name, test_id, reset) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_LAB_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer  ${getToken()}`,
        },
      };

      const { data } = await api.post(
        "subtests/create",
        { name, description, test_id },
        config
      );
      dispatch({
        type: ADD_LAB_SUCCESS,
        payload: data,
      });
      reset();
      await swal("Good job!", "test was added successfully", "success");
    } catch (error) {
      await swal("Opps!", "Failed to add test", "error");

      dispatch({
        type: ADD_LAB_FAIL,
      });
    }
  };

export const getLabAction = (token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LAB_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.get("subtests", config);

    dispatch({
      type: GET_LAB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LAB_FAIL,
    });
  }
};

export const deleteLabAction = (token, id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_LAB_REQUEST,
    });

    const { data } = await api.delete(
      `subtests/delete/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer  ${getToken()}`,
        },
      }
    );
    dispatch({
      type: DELETE_LAB_SUCCESS,
      payload: data,
    });
    await swal("Good job!", "test was deleted successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to delete test", "error");
    dispatch({
      type: DELETE_LAB_FAIL,
    });
  }
};

export const updateLabAction =
  (description, name, test_id, id, reset) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_LAB_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer  ${getToken()}`,
        },
      };

      const { data } = await api.put(
        "subtests/update/" + id,
        { name, description, test_id },
        config
      );
      dispatch({
        type: ADD_LAB_SUCCESS,
        payload: data,
      });
      reset();
      await swal("Good job!", "test was updated successfully", "success");
    } catch (error) {
      await swal("Opps!", "Failed to update test", "error");

      dispatch({
        type: ADD_LAB_FAIL,
      });
    }
  };
