import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getDrugsAction } from "../../../../actions/drugsAction";
import swal from "sweetalert";
import { useReactToPrint } from "react-to-print";
import { deleteTreatments, saveTreatments } from "./treatments_operation";
import FirstPrintPage from "../../../../print/printTremnats/Invistigation_print";

const Treatments = (props) => {
  const dispatch = useDispatch();
  const userlogin = useSelector((state) => state.userLogin);
  const { userInfo } = userlogin;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  let isComplete = props.state.state == "completed";

  useEffect(() => {
    dispatch(getDrugsAction(userInfo.token));
  }, []);
  const getDrugs = useSelector((state) => state.getDrugs);
  const { getDrugsData } = getDrugs;
  let drugsList = Array();
  getDrugsData?.forEach((e) => {
    drugsList.push({ value: e.id, label: e.name });
  });

  const [state, setState] = useState({ drug: "", dosage: "", name: "" });

  const [error, setError] = useState({ drug: "" });
  const handleAdd = () => {
    if (list.length === 0) {
    } else {
      let termnet = [];
      let notes = [];
      list.forEach((e) => {
        
        termnet.push(e.drug.value);
        notes.push(e.dosage);
      });
      

      if (list.length > 0) {
        saveTreatments(
          termnet,
          notes,
          props.state.id,
          props.state.patient.id,
          reset
        );
      }
      
    }
  };
  const handleSubmit = () => {
    
    
    if (validate() === true) {
      setList([...list, state]);
      
    }
    document.getElementById("AddDurgFormId").reset();
    setState({ drug: "", dosage: "", name: "" });
  };
  const [list, setList] = useState([]);
  
  const validate = () => {
    let tempError = error;

    if (state.drug === null || state.drug === "") {
      tempError = {
        ...tempError,
        drug: "required",
      };
    } else {
      tempError = { ...tempError, drug: "" };
    }

    
    
    if (tempError.drug === "") {
      return true;
    } else {
      setError(tempError);
      return false;
    }
  };
  const reset = () => {
    setList([]);
  };
  let myList = [];
  let addedList = [];
  props.state?.treatment?.forEach((e, index) => {
    
    addedList.push(
      <tr key={index}>
        <td>{e.drug.name}</td>
        <td>{e.note}</td>
        <td>
          <button
            disabled={isComplete}
            className="btn btn-danger"
            onClick={(event) => {
              
              deleteTreatments(e.id);
            }}
          >
            <i className="fas fa-trash  text-white"> </i>
          </button>
        </td>
      </tr>
    );
  });
  list.forEach((e, index) => {
    
    myList.push(
      <tr key={index + 3456}>
        <td>{e.name}</td>
        <td>
          <input
            type="text"
            className="form-control ml-1 mr-1"
            id="Dose"
            defaultValue={e.dosage}
            disabled={true}
            onChange={(event) => {
              let temp = [...list];
              temp.forEach((d) => {
                if (e.drug !== d.drug) {
                  if (e.target === undefined) {
                    d.dosage = "";
                  } else {
                    d.dosage = e.target.value;
                  }
                }
              });
              setList([...temp]);
            }}
            placeholder="Dose and Duration"
          />
        </td>
        <td>
          <button
            className="btn btn-danger"
            disabled={isComplete}
            onClick={(event) => {
              
              let temp = [];
              list.forEach((d) => {
                if (e.drug !== d.drug) {
                  temp.push(d);
                }
              });
              setList([...temp]);
            }}
          >
            <i className="fas fa-trash text-white"> </i>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <section>
      <div className="card">
        <div className="card-header bg-info">
          <h4 className="text-uppercase d-inline-block">Treatment </h4>
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <Form id="AddDurgFormId">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Select
                        id="inputSupplier"
                        // className="form-control border-info"
                        classNamePrefix="select test"
                        name="form-scientific_name_id-name"
                        isDisabled={isComplete}
                        isClearable={false}
                        isSearchable={true}
                        style={{ borderRadius: "6px" }}
                        isClearable={false}
                        value={state.drug}
                        style={{ borderRadius: "6px" }}
                        isSearchable={true}
                        onChange={(e) => {
                          
                          
                          if (e !== null && e !== undefined && e.length !== 0) {
                            setState({
                              ...state,
                              drug: e,
                              name: e.label,
                            });
                            setError({ ...error, drug: "" });
                          } else {
                            setState({ ...state, drug: "" });
                          }
                        }}
                        options={drugsList}
                      />
                      {/*<div style={{fontSize: 12, color: "red"}}>{error.drug}</div>*/}
                    </Form.Group>
                    <div style={{ fontSize: 12, color: "red" }}>
                      {error.drug}
                    </div>
                  </Form>
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control ml-1 mr-1"
                    id="Dose"
                    value={state.dosage}
                    disabled={isComplete}
                    onChange={(e) => {
                      setState({ ...state, dosage: e.target.value });
                    }}
                    placeholder="Dose and Duration"
                  />
                </td>
                <td>
                  <button onClick={handleSubmit} className="btn btn-primary">
                    {" "}
                    <i className="fas fa-plus text-white"> </i>{" "}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered">
            <thead className="bg-info">
              <tr>
                <th className="w-25">Drug</th>
                <th>Dose & Duration</th>
                <th className="w-25">.</th>
              </tr>
            </thead>
            <tbody>{myList}</tbody>
          </table>
          <div className="w-100 text-center">
            <button
              disabled={isComplete}
              className="btn btn-success btn-lg mt-3 w-50"
              onClick={handleAdd}
            >
              Submit
            </button>

            {/*  */}
            <br />

            <div hidden>
              <FirstPrintPage
                type={"treatments"}
                ref={componentRef}
                state={props.state}
                treatment={props.state?.treatment}
              />
            </div>
            <table className="table table-bordered mt-3">
              <thead className="bg-info">
                <tr>
                  <th className="w-25">Drug</th>
                  <th>Dose & Duration</th>
                  <th className="w-25">.</th>
                </tr>
              </thead>

              <tbody>{addedList}</tbody>
            </table>

            <button
              disabled={isComplete}
              className="btn btn-primary btn-lg mt-3 w-50"
              onClick={(event) => {
                handlePrint();
              }}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Treatments;
