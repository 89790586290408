import {
  ADD_INVESTIGATION,
  ADD_VISIT,
  DELETE_INVESTIGATION,
  EDIT_HISTORY_VISIT,
  GET_PATINET_VISIT,
  SET_TO_DEF,
  UPDATE_INVESTIGATION,
} from "../../../constants/patinetConstatnt";
import api, { logout } from "../../../constants/api";
import store from "../../../store";
import swal from "sweetalert";
import { getToken } from "../../../reducers/userReducers";
import {
  ADD_CVS,
  ADD_IMAGE,
  DELETE_IMAGE,
} from "./VisitationImageSection/VisitationImage";
import {
  ADD_TREATMENT,
  DELETE_TREATMENT,
} from "./TreatmentsSection/treatments_operation";
import { DELETE_CVS } from "../../../actions/pationactions";
import { GetFormattedDate } from "../../../constants/labConstants";
import { SET_EXAMINATIONS } from "./ExaminationSection/examination_operation";
var dateFormat = require("dateformat");

export default function eachVisitDetalis(
  state = { patient: {}, ranges: {}, types: {}, isLoaded: false, found: false },
  action
) {
  switch (action.type) {
    case GET_PATINET_VISIT: {
      return action.payload;
    }
    case SET_STATE_TO_LOADING: {
      return {
        patient: {},
        ranges: {},
        types: {},
        isLoaded: false,
        found: false,
      };
    }
    case EDIT_HISTORY_VISIT: {
      return {
        ...state,
        medical_history: action.payload,
      };
    }
    case UPDATE_INVESTIGATION: {
      let myData = state.visits;
      const pay = action.payload;

      myData.forEach((element, secIndex) => {
        let invistigation = element.invistigation;
        invistigation.forEach((ex, index) => {
          if (ex.test.id == pay.test.id) {
            invistigation[index] = pay;
          }
        });
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case DELETE_IMAGE: {
      let myData = state.visits;
      const id = action.payload;

      myData.forEach((element, secIndex) => {
        let images = element.images;
        let temp = [];
        images.forEach((ex, index) => {
          if (ex.id != id) {
            temp.push(ex);
          }
        });
        myData[secIndex].images = temp;
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case DELETE_CVS: {
      let myData = state.patient.patient.cvs;

      const id = action.payload;
      let temp = [];
      myData.forEach((element, secIndex) => {
        if (element.id != id) {
          temp.push(element);
        }
      });

      let tet = state;
      tet.patient.patient.cvs = temp;

      return {
        ...state,

        ...tet,
      };
    }

    case ADD_CVS: {
      let myData = state;
      myData.patient.patient.cvs = [
        ...myData.patient.patient.cvs,
        ...action.payload,
      ];

      return {
        ...state,
        ...myData,
      };
    }

    case ADD_IMAGE: {
      let myData = state.visits;
      const visit_id = action.payload.visit_id;
      const images = action.payload.images;
      myData.forEach((element, secIndex) => {
        if (element.id == visit_id) {
          myData[secIndex].images = [...myData[secIndex].images, ...images];
        }
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case ADD_TREATMENT: {
      let myData = state.visits;
      const visit_id = action.payload.visit_id;
      const data = action.payload.data;
      myData.forEach((element, secIndex) => {
        if (element.id == visit_id) {
          myData[secIndex].treatment = [...myData[secIndex].treatment, ...data];
        }
      });

      return {
        ...state,

        visits: myData,
      };
    }

    case ADD_INVESTIGATION: {
      let myData = state.visits;
      const visit_id = action.payload.visit_id;
      const data = action.payload.data;
      myData.forEach((element, secIndex) => {
        if (element.id == visit_id) {
          myData[secIndex].invistigation = [
            ...myData[secIndex].invistigation,
            ...data,
          ];
        }
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case DELETE_INVESTIGATION: {
      let myData = state.visits;
      const id = action.payload;

      myData.forEach((element, secIndex) => {
        let invistigation = element.invistigation;
        let temp = [];
        invistigation.forEach((ex, index) => {
          if (ex.id != id) {
            temp.push(ex);
          }
        });
        myData[secIndex].invistigation = temp;
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case DELETE_TREATMENT: {
      let myData = state.visits;
      const id = action.payload;

      myData.forEach((element, secIndex) => {
        let treatment = element.treatment;
        let temp = [];
        treatment.forEach((ex, index) => {
          if (ex.id != id) {
            temp.push(ex);
          }
        });
        myData[secIndex].treatment = temp;
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case EDIT_VISIT_STATS: {
      let myData = state.visits;
      const id = action.payload.visit_id;
      const status = action.payload.status;
      myData.forEach((element, secIndex) => {
        if (element.id == id) {
          myData[secIndex].state = status;
          myData[secIndex].price = action.payload.price;
        }
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case SET_EXAMINATIONS: {
      let myData = state.visits;
      const id = action.payload.id;
      const status = action.payload.data;
      myData.forEach((element, secIndex) => {
        if (element.id == id) {
          myData[secIndex].examination = status;
        }
      });

      return {
        ...state,

        visits: myData,
      };
    }
    case EDIT_CURRENT: {
      let myData = state.visits;
      const id = action.payload.visit_id;
      const status = action.payload.data;
      myData.forEach((element, secIndex) => {
        if (element.id == id) {
          myData[secIndex].currentLlenuss = status;
        }
      });

      return {
        ...state,
        visits: myData,
      };
    }
    case DELETE_VISIT: {
      let myData = state.visits;
      const id = action.payload;
      let visits = [];
      myData.forEach((element, secIndex) => {
        if (element.id != id) {
          visits.push(element);
        }
      });

      return {
        ...state,

        visits: visits,
      };
    }

    default:
      return state;
  }
}

export async function getPatientVisitis(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("patients/get/" + id, config);
    const data = response.data;

    store.dispatch({
      type: GET_PATINET_VISIT,
      payload: {
        patient: data,
        visits: data.visits,
        found: true,
        medical_history: data.patient.medical_history,
        isLoaded: true,
      },
    });
  } catch (error) {
    store.dispatch({
      type: GET_PATINET_VISIT,
      payload: {
        found: false,
        isLoaded: true,
      },
    });

    if (error.response?.status === 401) {
      logout();
    }
  }
}

export async function saveMedicalHistory(types, ranges, notes, patinetId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "medical-history/create",
      {
        patient_id: patinetId,
        disease: types,
        type: types,
        range: ranges,
        note: notes,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: EDIT_HISTORY_VISIT, payload: data });
    await swal(
      "Good job!",
      "medical history was saved successfully",
      "success"
    );
    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save medical history", "error");
    }
    return false;
  }
}

export async function updateMedicalHistory(
  types,
  ranges,
  notes,
  patinetId,
  ids
) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "medical-history/update",
      {
        patient_id: patinetId,
        disease: types,
        type: types,
        range: ranges,
        note: notes,
        id: ids,
      },
      config
    );
    const data = response.data;

    store.dispatch({ type: EDIT_HISTORY_VISIT, payload: data });
    await swal(
      "Good job!",
      "medical history was saved successfully",
      "success"
    );
    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save medical history", "error");
    }
    return false;
  }
}

export async function createVisti(patinetId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.post(
      "visits/create",
      {
        price: "25000",
        patient_id: patinetId,
        follow_date: dateFormat(new Date(), "yyyy-mm-dd HH:mm:ss"),
        state: "notes here",
      },
      config
    );

    const data = response.data;
    store.dispatch({ type: ADD_VISIT, payload: patinetId });

    await swal("Good job!", "visits was saved successfully", "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else if (error.response?.status === 404) {
      await swal("Opps!", "The patient already have a visit today", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to create visits", "error");
    }
    return false;
  }
}

export async function deleteVisit(visitId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete("visits/delete/" + visitId, config);
    const data = response.data;
    store.dispatch({ type: DELETE_VISIT, payload: visitId });

    await swal("Good job!", "visit was deleted successfully", "success");
    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to deleted visit", "error");
    }
    return false;
  }
}

export async function updateCompletesitPrice(patinetId, price, id, status) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "visits/update/" + id,
      {
        price: price,
        patient_id: patinetId,
        state: status,
      },
      config
    );

    const data = response.data;

    store.dispatch({
      type: EDIT_VISIT_STATS,
      payload: {
        visit_id: id,
        price: price,
        status: status,
      },
    });
    await swal("Good job!", `visits was ${status} successfully`, "success");

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to update visits staus", "error");
    }
    return false;
  }
}

export const DELETE_VISIT = "DELETE_VISIT";

export const EDIT_VISIT_STATS = "EDIT_VISIT_STATS";

export async function followUp(patinetId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.post(
      "visits/follow-up",
      {
        id: patinetId,
      },
      config
    );

    const data = response.data;
    store.dispatch({ type: FOLLOW_UP, payload: patinetId });

    await swal("Good job!", "visits was follow up successfully", "success");
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to follow up visits", "error");
    }
    return false;
  }
}
export const SET_STATE_TO_LOADING = "SET_STATE_TO_LOADING";
export const FOLLOW_UP = "FOLLOW_UP";
export const EDIT_CURRENT = "EDIT_CURRENT";
