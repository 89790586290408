import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";
import swal from "sweetalert";
import { EDIT_CURRENT } from "../create_visit_reducer";
import store from "../../../../store";
export async function createIllense(notes, types, visit_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "current-llenuss/create",
      {
        visit_id: visit_id,
        type: types,
        note: notes,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: EDIT_CURRENT,
      payload: {
        data: data,
        visit_id: visit_id,
      },
    });
    await swal(
      "Good job!",
      "history of current illences was saved successfully",
      "success"
    );

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal(
        "Opps!",
        'Failed to save "history of current illences ',
        "error"
      );
    }
    return false;
  }
}

export async function upDateIllences(notes, types, ids, visit_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "current-llenuss/update",
      {
        visit_id: visit_id,
        id: ids,
        type: types,
        note: notes,
      },
      config
    );

    const data = response.data;
    store.dispatch({
      type: EDIT_CURRENT,
      payload: {
        data: data,
        visit_id: visit_id,
      },
    });
    await swal(
      "Good job!",
      "history of current illences was updated successfully",
      "success"
    );

    return true;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal(
        "Opps!",
        "Failed to updated history of current illences ",
        "error"
      );
    }
    return false;
  }
}
