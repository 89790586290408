import swal from "sweetalert";
import api from "../constants/api";
import {
  ADD_OCCUPATION_REQUEST,
  ADD_OCCUPATION_SUCCESS,
  ADD_OCCUPATION_FAIL,
  GET_OCCUPATION_REQUEST,
  GET_OCCUPATION_SUCCESS,
  GET_OCCUPATION_FAIL,
  UPDATE_OCCUPATION_REQUEST,
  UPDATE_OCCUPATION_SUCCESS,
  UPDATE_OCCUPATION_FAIL,
} from "../constants/occupationConstatnts";
import { getToken } from "../reducers/userReducers";

export const addOccupation = (token, name, reset) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_OCCUPATION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.post(
      "occupations/create",
      { name },
      config
    );
    dispatch({
      type: ADD_OCCUPATION_SUCCESS,
      payload: data,
    });
    reset();
    await swal("Good job!", "occupations was added successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add occupation", "error");
    dispatch({
      type: ADD_OCCUPATION_FAIL,
    });
  }
};

export const getOccupationAction = (token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OCCUPATION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.get(
      "occupations",
      config
    );
    dispatch({
      type: GET_OCCUPATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_OCCUPATION_FAIL,
    });
  }
};

export const updateOccupation = (token, name, id, reset) => async (
  dispatch
) => {
  try {
    dispatch({
      type: UPDATE_OCCUPATION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer  ${getToken()}`,
      },
    };

    const { data } = await api.put(
      `occupations/update/${id}`,
      { name },
      config
    );
    dispatch({
      type: UPDATE_OCCUPATION_SUCCESS,
      payload: data,
    });
    reset();
    await swal("Good job!", "occupations was updated successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to update occupation", "error");
    dispatch({
      type: UPDATE_OCCUPATION_FAIL,
    });
  }
};
