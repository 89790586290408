import swal from "sweetalert";
import { getToken } from "../../../../reducers/userReducers";
import api, { logout } from "../../../../constants/api";
import store from "../../../../store";

export async function saveTreatments(drugs, notes, visit_id, patient_id,reset) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {

    const response = await api.post(
      "treatments/create",
      {
        visit_id: visit_id,
        patient_id: patient_id,
        drug_id: drugs,
        note: notes,
      },
      config
    );

    const data = response.data;
    reset();
    store.dispatch({type:ADD_TREATMENT , payload:{visit_id:visit_id ,data:data}})

    await swal("Good job!", "Treatments was saved successfully", "success");

    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Treatments", "error");
    }
    return false;
  }
}

export async function updateExamination(
  types,
  results,
  visit_id,
  patient_id,
  ids
) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {

    const response = await api.post(
      "treatments/update",
      {
        visit_id: visit_id,
        type: types,
        result: results,
        patient_id: patient_id,
        ids: ids,
      },
      config
    );

    const data = response.data;
    store.dispatch({type:ADD_TREATMENT , payload:{visit_id:visit_id ,data:data}})

    await swal("Good job!", "Treatments was saved successfully", "success");

    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to save Treatments", "error");
    }
    return false;
  }
}

export async function deleteTreatments(id) {
  
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
  
    const response = await api.delete(
      "treatments/delete/" + id,

      config
    );

    const data = response.data;

    await swal("Good job!", "Treatments was deleted successfully", "success");
    store.dispatch({type:DELETE_TREATMENT , payload:id})

    return true;
  } catch (error) {
    
    if (error.response?.status === 401) {
      logout();
    } else if (error.response?.status === 422) {
      await swal("Opps!", "The given data was invalid", "error");
      return false;
    } else {
      await swal("Opps!", "Failed to delete Treatments", "error");
    }
    return false;
  }
}

export const DELETE_TREATMENT ="DELETE_TREATMENT";
export const ADD_TREATMENT ="ADD_TREATMENT";
