import React, { Component, useRef, useState } from "react";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { saveLastReport, updateLastReport } from "./LastReport_operation";
import { useReactToPrint } from "react-to-print";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FirstPrintPage from "../../../../print/printTremnats/Invistigation_print";

const LastReportSection = (props) => {
  let isComplete = props.state.state=="completed";

  const getState = () => {
    
    if (
      props.state.dischargReport == null ||
      props.state.dischargReport === undefined ||
      props.state.dischargReport.note == null ||
      props.state.dischargReport.note == "" ||
      props.state.dischargReport.note == undefined
    ) {
      
      return EditorState.createEmpty();
    } else {
      
   

      return EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(JSON.parse(props.state?.dischargReport?.note))
        )
      );
    }
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [state, setState] = useState({
    editorState: getState(),
  });

  const onEditorStateChange = (editorState) => {
    const rawState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );
     //TODO get From Obj

    setState({
      editorState,
    });
  };

  return (
    <section>
      <div className="card">
        <div className="card-header bg-info">Report Visit</div>
        <div className="card-body">
          <div>
            <div className="editor border">
              <Editor
                editorState={state.editorState}
                disabled={isComplete}

                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                disable={isComplete}
                onEditorStateChange={onEditorStateChange}
                defaultEditorState={{
                  blocks: [
                    {
                      key: "afse5",
                      text: "sdfds dfsdfsd sdfsdfs sdfs",
                      type: "unstyled",
                      depth: 0,
                      inlineStyleRanges: [
                        { offset: 5, length: 21, style: "BOLD" },
                        { offset: 14, length: 12, style: "UNDERLINE" },
                        { offset: 21, length: 5, style: "STRIKETHROUGH" },
                      ],
                      entityRanges: [],
                      data: {},
                    },
                  ],
                  entityMap: {},
                }}
                toolbar={{
                  options: ["inline", "history",'fontSize','colorPicker'],
                  inline: { inDropdown: false },
                }}
              />
            </div>{" "}
            <div style={{ display: "none" }}>
              {" "}
              <FirstPrintPage
                ref={componentRef}
                type={"report"}
                state={props.state}
                report={state.editorState.getCurrentContent()}
              />
            </div>
            <div className="row text-center mt-3">
              <div className="col-md-6">
                <button
                  className="btn btn-success w-75"
                  disabled={isComplete}
                  onClick={(e) => {
                    const rawState = JSON.stringify(
                      convertToRaw(state.editorState.getCurrentContent())
                    );
                    if (
                      props?.state?.dischargReport === null ||
                      props?.state?.dischargReport === undefined
                    ) {
                      
                      saveLastReport(
                        JSON.stringify(rawState),
                        props?.state?.id
                      );
                    } else {
                      
                      updateLastReport(
                        JSON.stringify(rawState),
                        props?.state?.id,
                        props?.state?.dischargReport.id
                      );
                    }
                    
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="col-md-6">
                <button
   disabled={isComplete}
                  className="btn btn-primary w-75"
                  onClick={(event) => {
                    handlePrint();
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LastReportSection;
