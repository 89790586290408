import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMainTestAction } from "../../actions/mainTestAction";
import {
  addLabAction,
  getLabAction,
  deleteLabAction,
  updateLabAction,
} from "../../actions/labAction";
import "./TestStyle.css";
import Select from "react-select";

function AddTest() {
  const [name, setName] = useState();
  const [selectedId, setselectedId] = useState();
  const [category, setCategory] = useState();
  const userlogin = useSelector((state) => state.userLogin);
  const getLab = useSelector((state) => state.getLab);
  const getMainTests = useSelector((state) => state.getMainTest);
  const dispatch = useDispatch();
  const { userInfo } = userlogin;

  const { getLabData } = getLab;
  const { getMainTestData } = getMainTests;
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getLabAction(userInfo.token));
    dispatch(getMainTestAction(userInfo.token));
  }, []);

  const reset = () => {
    setName("");
    setCategory({});
    setselectedId(undefined);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    
    if (name === undefined || category === undefined) {
      
      return;
    }
    if (getMainTestData) {
    
      if (selectedId == null || selectedId === undefined) {
        

        await dispatch(
          addLabAction("description", name, category.value, reset)
        );
      } else {
        
        // return
        await dispatch(
          updateLabAction(
            "description",
            name,
            category.value,
            selectedId,
            reset
          )
        );
      }
      await dispatch(getLabAction(userInfo.token));
    }
  };
  const deleteHandler = async (id) => {
    const { userInfo } = userlogin;
    await dispatch(deleteLabAction(userInfo.token, id));
    await dispatch(getLabAction(userInfo.token));
  };
  let counterTest = 0;
  let labForm = [];
  getLabData?.forEach((labData, index) => {
    if (
      labData.name?.includes(search) ||
      labData.test?.name?.includes(search)
    ) {
      counterTest++;

      labForm.push(
        <tr key={labData.id}>
          <td>{counterTest}</td>
          <td>{labData?.name}</td>
          <td>{labData.test?.name}</td>
          <td>
            <button
              className="bg-success btn-circle btn-sm"
              onClick={(e) => {
                
                setName(labData.name);
                setselectedId(labData.id);

                setCategory({
                  label: labData.test.name,
                  value: labData.test.id,
                });
              }}
            >
              <i className="fas fa-pen"> </i>
            </button>
            <button
              onClick={() => deleteHandler(labData?.id)}
              className="bg-red btn-circle btn-sm"
            >
              <i className="fas fa-trash"> </i>
            </button>
          </td>
        </tr>
      );
    }
  });

  let options = [];

  if (getMainTestData?.length !== 0) {
    getMainTestData?.forEach((refDoctor) => {
      options.push({ value: refDoctor.id, label: refDoctor.name });
    });
  }
  return (
    <div className="card">
      <div className="card-header bg-info">
        <p>Add Test</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" onSubmit={submitHandler}>
              <div className="form-group">
                <label htmlFor="testName" className="col-form-label">
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="testName"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-group-lg">
                <label className=" col-form-label">MainTest</label>

                <Select
                  id="okimeorbimeo"
                  classNamePrefix="select test"
                  name="form-scientific_name_id-name"
                  isDisabled={false}
                  required
                  isClearable={false}
                  isSearchable={true}
                  value={category}
                  style={{ borderRadius: "6px" }}
                  onChange={(e) => {
                    
                    setCategory(e);
                    if (e !== null && e !== undefined) {
                    }
                  }}
                  options={options}
                />

                {/*<select*/}
                {/*  className='form-control select2bs4 d-inline'*/}
                {/*  name='state'*/}
                {/*  value={category}*/}
                {/*  onChange={e => setCategory(e.target.value)}*/}
                {/*>*/}
                {/*  {options()}*/}
                {/*</select>*/}
              </div>
              <br />

              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  <i className="fas fa-plus"></i>{" "}
                  {selectedId == null || selectedId == null ? "Add" : "Save"}
                </button>
              </div>
            </form>
            {selectedId != null && selectedId != undefined ? (
              <div
                className="form-group"
                onClick={(e) => {
                  setName("");
                  setselectedId(null);
                  setCategory(null);
                }}
              >
                <button className="btn btn-danger w-100">Cancel</button>
              </div>
            ) : null}
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            {/*table*/}
            <div className="row m-3">
              <div className="col-8">
                <div className="input-group">
                  <input
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                    type="search"
                    placeholder="Search by Test"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <table className="table table-bordered dataTable rounded-top ">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>Name</th>
                  <th>MainTest</th>
                  {/*<th className="w-50">Description</th>*/}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{labForm}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTest;
