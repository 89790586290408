import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  createVisti,
  followUp,
} from "../todayPatient/EachVisition/create_visit_reducer";
import { Link } from "react-router-dom";
import { getSearchPatient } from "./searchPatinetReducer";

function SearchScreen() {
  const [phoneSort, setPhoneSort] = useState(false);
  const [perPage, setPerPage] = useState(10);

  function changePhoneStateSort() {
    setPhoneSort(!phoneSort);
  }

  const [searchState, setSearch] = useState({
    startDate: "",
    endDate: "",
    query: "",
    name: "",
    diff: "",
    phone: "",
  });
  const GetFormattedDate = () => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + day;
  };
  const userlogin = useSelector((state) => state.userLogin);
  const [page, setPage] = useState(0);

  const { userInfo } = userlogin;
  const { visits, total } = useSelector((state) => state.searchPatinetReducer);

  let patientList = [];
  let index = 0;
  useEffect(() => {
    console.log("visits : ",visits)
    getSearchPatient(
      searchState.startDate,
      searchState.endDate,
      searchState.query,
      searchState.name,
      searchState.phone,
      searchState.diff,
      page,
      perPage
    );
  }, [perPage]);
  const handleSubmit = (pr) => {
    pr.preventDefault();

    setPage(0);
    getSearchPatient(
      searchState.startDate,
      searchState.endDate,
      searchState.query,
      searchState.name,
      searchState.phone,
      searchState.diff,
      page,
      perPage
    );
  };

  visits?.forEach((visit) => {
    index++;
    patientList.push(
      <tr key={index}>
        <td>{index}</td>
        <td>{visit.fullname}</td>
        <td>{visit.phone} </td>
        <td>{visit.code} </td>
        <td className="text-center">
          {GetFormattedDate() ==
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-info"
              onClick={(e) => {
                createVisti(visit.id, userInfo.token);
              }}
            >
              New Visit
            </button>
          )}
        </td>
        <td className="text-center text-danger text-bold " id="sizeFont">
          {visit.cvs.length}
        </td>
        <td className="text-center text-danger text-bold " id="sizeFont">
          <ins>
            {" "}
            <Link
              className="text-red text-u"
              to={{
                pathname: `/tabsVisit/${visit.id}`,
                state: { patientKey: visit },
              }}
            >
              {" "}
              {visit.visits}
            </Link>
          </ins>
        </td>

        <td className="text-center">
          {GetFormattedDate() ==
          visit.visit_last_one.follow_date.toString().split(" ")[0] ? null : (
            <button
              className="btn btn-outline-warning"
              onClick={(e) => {
                followUp(visit.id);
              }}
            >
              Follow up
            </button>
          )}
        </td>
      </tr>
    );
  });
  return (
    <div className="rounded-top">
      <form className="card rounded-top" onSubmit={handleSubmit}>
        <div className="card-header bg-info">Search In Patient</div>
        <div className="card-body">
          <div className="form mb-3 w-100">
            <div className="active-purple-4">
              <div className="d-flex">
                <div>
                  <label htmlFor="patinetCodeSearch" className="col-form-label">
                    Patient Code
                  </label>
                  <input
                    id="patinetCodeSearch"
                    className="form-control"
                    type="text"
                    onChange={(event) => {
                      setSearch({ ...searchState, query: event.target.value });
                    }}
                    placeholder="Patient Code "
                    aria-label="Search"
                  />
                </div>
                <div className="ml-2">
                  <label htmlFor="patinetCodeSearch" className="col-form-label">
                    Patient Name
                  </label>
                  <input
                    id="patinetCodeSearch"
                    className="form-control"
                    type="text"
                    onChange={(event) => {
                      setSearch({ ...searchState, name: event.target.value });
                    }}
                    placeholder="Patient  Name "
                    aria-label="Search"
                  />
                </div>
                <div className="ml-2">
                  <label htmlFor="patinetCodeSearch" className="col-form-label">
                    Patient Phone
                  </label>
                  <input
                    id="patinetCodeSearch"
                    className="form-control"
                    type="text"
                    onChange={(event) => {
                      setSearch({ ...searchState, phone: event.target.value });
                    }}
                    placeholder="Patient Phone"
                    aria-label="Search"
                  />
                </div>

                <div className="ml-2">
                  <label htmlFor="diffSearch" className="col-form-label">
                    Differential diagnosis
                  </label>
                  <input
                    id="diffSearch"
                    className="form-control"
                    type="text"
                    onChange={(event) => {
                      setSearch({ ...searchState, diff: event.target.value });
                    }}
                    placeholder="Differential diagnosis
                    "
                    aria-label="Search"
                  />
                </div>
              </div>
            </div>

            <div className="active-purple-4 mt-3">
              <label htmlFor="diagnosisSearch" className="col-form-label">
                between Two date
              </label>
              <div className="row">
                <div className="col-6">
                  <span>From</span>{" "}
                  <input
                    type="date"
                    className="form-control"
                    selected={searchState.startDate}
                    onChange={(date) =>
                      setSearch({
                        ...searchState,
                        startDate: date.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  <span>To</span>{" "}
                  <input
                    type="date"
                    className="form-control"
                    selected={searchState.endDate}
                    onChange={(date) =>
                      setSearch({
                        ...searchState,
                        endDate: date.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-outline-info w-50">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="mr-1 ml-1">
        <table className="table table-bordered w-100">
          <thead id="tableHeader" className="w-100">
            <tr>
              <th>#</th>
              <th>
                <span> Patient name</span>
                <span className="float-right">
                  {/*TODO when click on this , from up go to down and else.*/}
                  {phoneSort ? (
                    <i
                      className="fas fa-sort-up"
                      onClick={changePhoneStateSort}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-sort-down"
                      onClick={changePhoneStateSort}
                    ></i>
                  )}
                </span>
              </th>
              <th>
                <span>phone</span>
                <span className="float-right">
                  <i className="fas fa-sort-up"></i>
                  <i className="fas fa-sort-down"></i>
                </span>
              </th>
              <th>
                <span>Code</span>
                <span className="float-right">
                  <i className="fas fa-sort-up"></i>
                  <i className="fas fa-sort-down"></i>
                </span>
              </th>
              <th>
                <span> New Visit</span>
                <span className="float-right">
                  <i className="fas fa-sort-up"></i>
                  <i className="fas fa-sort-down"></i>
                </span>
              </th>
              <th>
                <span>Patient CVs</span>
              </th>
              <th>visitations</th>
              <th>Follow Up</th>
            </tr>
          </thead>
          <tbody>{patientList}</tbody>
        </table>
        <div class="d-flex align-items-center gap-2">
          <div
            className="mr-2"
            style={{
              width: "7%",
            }}
          >
            <select
              className="custom-select "
              onChange={(e) => setPerPage(e.target.value)}
              // onBlur={handleSubmit}
              style={{
                padding: "8px 12px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                backgroundColor: "#fff",
                color: "#495057",
                fontSize: "1rem",
                outline: "none",
              }}
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {total > 0 ? (
            <div className="text-center w-100">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                onPageChange={(ev) => {
                  getSearchPatient(
                    searchState.startDate,
                    searchState.endDate,
                    searchState.query,
                    searchState.name,
                    searchState.phone,
                    searchState.diff,
                    ev.selected,
                    perPage
                  );

                  setPage(ev.selected);
                }}
                pageNum={page}
                forcePage={page}
                pageCount={total / perPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SearchScreen;
